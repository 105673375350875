#IllustrationsPage {
  // width: 100vw;
  height: 100vh;
  background-color: $seashell;

  .text {
    left: 50vw;
    top: 50vh;
    transform: translate(-50%, -50%);

    pointer-events: none;

    h1 {
      font-size: 5rem;
      font-family: "NewYork", "Prettywise", "Didot";
    }

    p {
      font-size: 0.8rem;
      font-family: "Didot";
    }
  }

  canvas {
    height: 100%;
  }
}
