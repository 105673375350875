#IllustrationDisplay {
  .illustration {
    cursor: pointer;
    width: 33vw;
    height: 65vh;
    overflow: hidden;
    margin-right: 20px;

    img {
      transform: scale(1.2);
    }

    .hoverIndications {
      width: 100%;
      height: 100%;
      opacity: 0.5;
      top: 0;
      font-size: 2.5rem;
      font-weight: 900;
      color: $seashell;
    }

    .blur {
      filter: blur(2px);
    }
  }
}
