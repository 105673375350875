#Model3D {
  width: 100vw;
  height: 100vh;

  canvas {
    height: 100%;
  }

  .testLink {
    top: 500px;
    left: 500px;
  }
}
