#Footer {
  padding: 30px 0 60px 0;

  * {
    color: $cinerous;
    font-family: "Didot";
    font-style: italic;
    text-decoration: underline;
    font-weight: 100;
  }

  ul {
    margin-top: 30px;
    width: 30vw;
    li {
      font-size: 1.4rem;
    }
  }
}

@media screen and (max-width: 825px) {
  #Footer {
    padding: 0px 0 60px 0;

    ul {
      width: 40vw;
    }
  }
}
@media screen and (max-width: 700px) {
  #Footer {
    ul {
      width: 50vw;
    }
  }
}
@media screen and (max-width: 500px) {
  #Footer {
    h3 {
      font-size: 2.5rem;
    }
    ul {
      width: 60vw;
    }
  }
}
@media screen and (max-width: 370px) {
  #Footer {
    ul {
      width: 80vw;
    }
  }
}
