#Button {
  display: inline-flex;
  background: $cinerous;
  padding: 30px 65px;
  font-family: "Didot";
  font-weight: bold;
  font-size: 1.3rem;
  color: $seashell;
  cursor: pointer;
  overflow: hidden;
  isolation: isolate;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 100%;
    width: 100vw;
    height: 100%;
    background: $charleston;
    mix-blend-mode: difference;
    opacity: 0.33;
    transition: 0.5s ease-out right;
  }

  &:hover::after {
    right: 0;
  }

  & {
    scale: 0.8;
  }
}
