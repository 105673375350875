#Cursor {
  position: fixed;
  width: 40px;
  height: 40px;
  z-index: 10;
  border: 1px solid $cinerous;
  border-radius: 50%;
  pointer-events: none;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  // -webkit-transition-duration: 100ms;
  // transition-duration: 100ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  will-change: width, height, transform, border;
  transition-property: border-color, transform;
  // transition: transform ease-in 0.2s;
}
