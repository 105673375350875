.img-fluid {
  width: 100%;
}

.none {
  display: none;
}

.hidden {
  overflow: hidden;
}

.transparent {
  opacity: 0;
}

.border {
  border: 1px solid red;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.leftSide {
  grid-column: span 6;
}

.rightSide {
  grid-column: span 6;
}
