@font-face {
  font-family: "NewYork";
  src: url("../../Fonts/NewYork.otf") format("otf");
  font-weight: 400;
}

@font-face {
  font-family: "Didot";
  src: url("../../Fonts/Didot\ LT\ Std\ Italic.otf") format("otf");
  font-weight: 400;
}

@font-face {
  font-family: "Avenir";
  src: url("../../Fonts/AvenirNextLTPro-Regular.otf") format("otf");
  font-weight: 400;
}

@font-face {
  font-family: "Prettywise";
  src: local("Prettywise") url("../../Fonts/Prettywise-Thin.ttf") format("ttf");
  font-weight: 400;
}
