#Navbar {
  padding: 30px 50px;
  height: 60px;
  z-index: 2;
  width: 100vw;
  position: fixed;
  top: 0;
  pointer-events: all;

  .logoSerotoninene {
    width: 32px;
    cursor: pointer;
  }

  .transitionFrame {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 100vw;
    position: fixed;
  }

  .frameOne {
    background-color: $cinerous;
  }

  .frameTwo {
    background-color: $silverPink;
  }
}
