.ShowcaseOneIllu {
  height: 100vh;
  padding: 0 100px;

  .illustration {
    width: 33vw;
  }

  .illustrationPresentation {
    width: 40vw;
    height: 70vh;
    padding: 20px;
    font-family: "Didot";
  }

  h3 {
    font-family: "Didot";
  }

  p {
    margin: 0 5vw;
    font-family: "Didot";
  }

  .price {
    font-size: 2rem;
    span {
      font-size: 2.5rem;
      font-family: "NewYork";
    }
  }
}

.ShowcaseOneIllu:nth-child(2n) {
  flex-direction: row-reverse;
}

@media screen and (min-width: 1081px) {
  .ShowcaseOneIllu {
    max-width: 1500px;
    margin: 0 auto;

    .illustrationPresentation {
      width: 33vw;
    }
  }
}

@media screen and (min-width: 1900px) {
  .ShowcaseOneIllu {
    max-width: 1700px;
    margin: 0 auto;

    .illustrationPresentation {
      width: 33vw;
    }
  }
}

@media screen and (max-width: 992px) {
  .ShowcaseOneIllu {
    padding: 0 70px;

    .price {
      font-size: 1.8rem;
      span {
        font-size: 2rem;
        font-family: "NewYork";
      }
    }
  }
}
@media screen and (max-width: 870px) {
  .ShowcaseOneIllu {
    .illustrationPresentation {
      padding: 40px 20px;
      h3 {
        font-size: 2.3rem;
      }
      p {
        font-size: 1.1rem;
        line-height: 2rem;
      }

      .price {
        font-size: 1.6rem;
        span {
          font-size: 1.8rem;
        }
      }

      #Button {
        padding: 23px 65px;
      }
    }
  }
}
@media screen and (max-width: 825px) {
  .ShowcaseOneIllu {
    .illustrationPresentation #Button {
      padding: 23px 50px;

      p {
        font-size: 1rem;
        line-height: 1.7rem;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .ShowcaseOneIllu {
    padding: 0 50px;
    .illustrationPresentation {
      padding: 70px 20px;

      h3 {
        font-size: 2rem;
      }

      p {
        font-size: 0.8rem;
        line-height: 1.3rem;
      }

      .price {
        font-size: 1.4rem;
        span {
          font-size: 1.6rem;
        }
      }

      #Button {
        padding: 23px 15px;
      }
    }

    .illustration {
      width: 40vw;
    }
  }
}
@media screen and (max-width: 570px) {
  .ShowcaseOneIllu,
  .ShowcaseOneIllu:nth-child(2n) {
    flex-direction: column;
    height: auto;
    .illustration {
      width: 80vw;
    }

    .illustrationPresentation {
      margin: 30px 0;
      width: 80vw;
      height: 50vh;
      padding: 20px 20px;

      p {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }
}
@media screen and (max-width: 370px) {
  .ShowcaseOneIllu {
    padding: 20px 0;
    .illustrationPresentation {
      h3 {
        font-size: 1.7rem;
      }
      p {
        font-size: 0.8rem;
        line-height: 1.3rem;
      }
    }
  }
}
