// Basic set up
* {
  overscroll-behavior: none;
  font-family: "Avenir", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  pointer-events: auto;
  color: inherit;
  font-family: inherit;
  cursor: pointer;
}

// Text Styles
h1 {
  font-size: 150px;
  line-height: 136%;
}

h2 {
  font-size: 65px;
  font-family: "Didot";
  line-height: 115%;
  font-style: italic;
}

h3 {
  font-size: 50px;
  font-family: "Didot";
  font-weight: 900;
  line-height: 56px;
}

h4 {
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
}

h5 {
  font-size: 20px;
  font-weight: 200;
  line-height: 34px;
}

p {
  font-size: 20px;
  font-weight: 100;
  line-height: 34px;
}
