#Test {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: $seashell;

  h1 {
    line-height: 50%;
    margin-bottom: 1rem;
  }

  h1 span {
    font-family: "NewYork", "Didot";
    font-size: 6rem;
    display: inline-block;
  }

  p {
    text-align: end;
    span {
      display: inline-block;
    }
  }

  .illustrationsDisplay {
    top: 22vh;
    left: 50px;
    padding-top: 20px;
    width: auto;
  }

  @media screen and (max-width: 650px) {
    h1 {
      margin-bottom: 0.5rem;
      span {
        font-size: 5rem;
      }
    }
  }

  @media screen and (max-width: 570px) {
    h1 {
      margin-top: 1rem;
      margin-bottom: 0.1rem;
      span {
        font-size: 4rem;
      }
    }
    p span {
      font-size: 1.2rem;
    }
  }
}

#Test2 {
  width: 100vw;
  height: 100vh;
  background-color: $seashell;

  .Herobanner {
    height: 100vh;
  }

  .section {
    height: 100vh;
  }

  .scrollArea {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
  }
}
